import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';
import SnackbarAlert from './SnackbarAlert';
import { accountDeletedEmail } from '../api/Aws/RemoteManagementApi';
import { useDeleteUserMutation } from '../../services/aiphoneCloud';
import { Field, Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { CloudUser, getCurrentUser } from '../../store/slices/usersSlice';
import { GridRowSelectionModel } from '@mui/x-data-grid';

interface DeleteAccountDialogProps {
  isOpen: boolean;
  onClose: () => void;
  selectionModel?: GridRowSelectionModel;
}

type AlertPayload = {
  message: string;
  type: 'error' | 'success' | 'warning' | 'info';
  isOpen: boolean;
};

const DeleteAccountDialog = ({ isOpen, onClose, selectionModel }: DeleteAccountDialogProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const isSelfDeletion = useMemo(() => location.pathname === '/profile', [location.pathname]);

  const reasonsList = useMemo(
    () =>
      isSelfDeletion
        ? {
            anotherProfile: t('Delete_Account_Reason_1'),
            safetyOfData: t('Delete_Account_Reason_2'),
            otherReason: t('Delete_Account_Reason_3')
          }
        : {
            policyViolation: t('Delete_Account_Manager_Reason_1'),
            userRequest: t('Delete_Account_Manager_Reason_2'),
            inactiveAccount: t('Delete_Account_Manager_Reason_3'),
            otherReason: t('Delete_Account_Manager_Reason_4')
          },
    [t, isSelfDeletion]
  );

  const userInfo: CloudUser | null = useSelector(getCurrentUser);
  const [deleteUser, { isLoading: isDeleting }] = useDeleteUserMutation();
  const [alert, setAlert] = useState<AlertPayload>({ message: '', type: 'success', isOpen: false });
  const [reason, setReason] = useState('');
  const [isDeletionConfirmed, setIsDeletionConfirmed] = useState(false);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, []);

  const handleDeleteAccount = async () => {
    try {
      if (!userInfo || (!isSelfDeletion && !selectionModel)) {
        throw new Error(t('Delete_Account_Incorrect_User_Id'));
      }
      const targetUserPublicId = !isSelfDeletion && selectionModel ? selectionModel[0] : userInfo.publicId;

      await deleteUser({ targetUserPublicId, accountDeletionReason: reason }).unwrap();

      if (isSelfDeletion) {
        await accountDeletedEmail({
          userEmail: userInfo.email,
          userName: `${userInfo.firstName} ${userInfo.lastName}`
        });
      }

      setAlert({ message: t('Delete_Account_Success'), type: 'success', isOpen: true });
      timeoutRef.current = setTimeout(() => {
        if (isSelfDeletion) {
          navigate('/auth/login');
        }
      }, 2000);
    } catch {
      setAlert({ message: t('Delete_Account_Error'), type: 'error', isOpen: true });
    }
  };

  const renderReasons = () =>
    Object.entries(reasonsList).map(([key, label]) => (
      <FormControlLabel key={key} value={label} control={<Radio />} label={label} />
    ));

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
      <SnackbarAlert
        type={alert.type}
        time={6000}
        text={alert.message}
        isOpen={alert.isOpen}
        onClose={() => setAlert({ ...alert, isOpen: false })}
      />
      <DialogTitle sx={styles.dialogTitle}>
        {t('Delete_Account_Title')}
        <IconButton aria-label="close" onClick={onClose} sx={styles.closeIconStyle}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Formik initialValues={{ confirmation: '' }} onSubmit={handleDeleteAccount}>
        {({ handleChange, errors, touched }) => (
          <Form>
            <DialogContent>
              <DialogContentText>{t('Delete_Account_Description')}</DialogContentText>
              <Box my={2}>
                <Field
                  name="confirmation"
                  as={TextField}
                  label={t('Enter_Delete_To_Confirm')}
                  fullWidth
                  error={touched.confirmation && !!errors.confirmation}
                  helperText={touched.confirmation && errors.confirmation}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleChange(e);
                    setIsDeletionConfirmed(e.target.value === 'DELETE');
                  }}
                />
              </Box>
              <Box mt={2}>
                <FormControl component="fieldset">
                  <FormLabel>{t('Delete_Account_Reason')}</FormLabel>
                  <RadioGroup name="deleteReason" onChange={(e) => setReason(e.target.value)}>
                    {renderReasons()}
                  </RadioGroup>
                </FormControl>
              </Box>
              <TextField
                label={t('Additional_Comments')}
                multiline
                rows={4}
                variant="filled"
                fullWidth
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                sx={{ mt: 2 }}
              />
            </DialogContent>
            <DialogActions sx={styles.dialogActions}>
              <Button onClick={onClose}>{t('Cancel')}</Button>
              <LoadingButton type="submit" color="error" loading={isDeleting} disabled={!isDeletionConfirmed}>
                {t('Delete')}
              </LoadingButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

const styles = {
  closeIconStyle: { position: 'absolute', right: 8, top: 8 },
  dialogTitle: { borderBottom: '1px solid silver' },
  dialogActions: { borderTop: '1px solid silver' }
};

export default DeleteAccountDialog;
