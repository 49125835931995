import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { TableRow, TableCell, IconButton } from '@mui/material';
import SwapVertIcon from '@mui/icons-material/SwapVert';

/**
 * <SortableRow> wraps a row with dnd-kit’s useSortable
 * providing a small drag-handle in the first cell.
 */
const SortableRow: React.FC<{
  id: string;
  children: React.ReactNode;
}> = ({ id, children }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

  const style: React.CSSProperties = {
    transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
    transition
  };

  return (
    <TableRow ref={setNodeRef} style={style}>
      <TableCell width="50px">
        <IconButton {...attributes} {...listeners} size="small">
          <SwapVertIcon />
        </IconButton>
      </TableCell>
      {children}
    </TableRow>
  );
};

export default SortableRow;
