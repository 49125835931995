// services/aiphoneCloud.tsx
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import CONFIG from 'config';
import { ContextType } from 'store/slices/usersSlice';

const baseQuery = fetchBaseQuery({
  baseUrl: CONFIG.apiEndpoint,
  prepareHeaders: (headers) => {
    //TODO: Add auth token to redux
    //const token = getState().auth.token;

    const token = JSON.stringify({ token: localStorage.getItem('token') });
    if (token) {
      headers.set('Authorization', `${token}`);
    }
    return headers;
  }
});

const baseQueryWithReauth = async (args: any, api: any, extraOptions: any) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 403) {
    const refreshTokenValue = localStorage.getItem('refreshToken');

    if (!refreshTokenValue) {
      // No refresh token available, clear storage and handle logout
      localStorage.clear();
      // TODO: Redirect the user to the login page or handle as needed
      return result;
    }

    const request = {
      userData: {
        refreshToken: refreshTokenValue
      }
    };

    try {
      // Attempt to refresh the token
      const refreshResponse = await fetch(`${CONFIG.openApiEndpoint}/userAuthorization?action=refreshToken`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${refreshTokenValue}`
        },
        body: JSON.stringify(request)
      });

      if (refreshResponse.ok) {
        const data = await refreshResponse.json();
        const { accessToken, idToken } = data;

        // Store the new tokens in localStorage
        if (accessToken && typeof accessToken === 'string') {
          localStorage.setItem('token', accessToken);
        } else {
          console.error('Invalid accessToken in refresh response:', accessToken);
        }

        if (idToken && typeof idToken === 'string') {
          localStorage.setItem('idToken', idToken);
        } else {
          console.error('Invalid idToken in refresh response:', idToken);
        }

        // Retry the original query with the new token
        result = await baseQuery(args, api, extraOptions);
      } else {
        // Refresh token failed, clear storage and handle logout
        console.error('Refresh token failed:', await refreshResponse.text());
        localStorage.clear();
        // TODO: Redirect the user to the login page or handle as needed
      }
    } catch (error) {
      // Handle network or other errors
      console.error('Error refreshing token:', error);
      localStorage.clear();
      // TODO: Redirect the user to the login page or handle as needed
    }
  }

  return result;
};

//TODO: Split aiphondCloudApi into multiple APIs for better organization (aiphoneCloudApi and remoteManagementApi)
export const aiphoneCloudApi = createApi({
  reducerPath: 'aiphoneCloudApi',
  baseQuery: baseQueryWithReauth, // Use the modified baseQuery with reauth logic
  tagTypes: ['Devices'],
  refetchOnMountOrArgChange: 5,
  endpoints: (build) => ({
    /** Start User Functions **/
    createUser: build.mutation({
      query: (data) => ({
        url: '/user?action=createUser',
        method: 'POST',
        body: data
      })
    }),
    getUserWithPublicId: build.query({
      query: (publicId) => `/user?action=getUserById&userPublicId=${publicId}`
    }),
    getUserAttr: build.query({
      query: (publicId) => `/user?action=getUserAttr&userPublicId=${publicId}`
    }),
    getUsersBySitePublicId: build.query({
      query: ({ qty, page, publicId }) =>
        `/user?action=getUserSiteList&sitePublicId=${publicId}&qty=${qty}&page=${page}`
    }),
    getUsersByBranchPublicId: build.query({
      query: ({ qty, page, publicId }) =>
        `/user?action=getUserBranchList&branchPublicId=${publicId}&qty=${qty}&page=${page}`
    }),
    getUsersWithNoBranchRoles: build.query({
      query: ({ qty, page }: { qty: string; page: string }) =>
        `/user?action=getUsersWithNoBranchRoles&qty=${qty}&page=${page}`
    }),
    getUsersWithBranchRoles: build.query({
      query: ({ qty, page }: { qty: string; page: string }) =>
        `/user?action=getUsersWithBranchRoles&qty=${qty}&page=${page}`
    }),
    getUsersWithGlobalRoles: build.query({
      query: ({ qty, page }: { qty: string; page: string }) =>
        `/user?action=getUsersWithGlobalRoles&qty=${qty}&page=${page}`
    }),
    updateUser: build.mutation({
      query: (data) => ({
        url: '/user?action=updateUser',
        method: 'PUT',
        body: data
      })
    }),
    adminUpdateUser: build.mutation({
      query: (data) => ({
        url: '/user?action=adminUpdateUser',
        method: 'PUT',
        body: data
      })
    }),
    getAssignableRoles: build.query({
      query: ({ contextPublicId, contextType }: { contextPublicId: string | null; contextType: ContextType }) =>
        `/user?action=getAssignableRoles&contextPublicId=${contextPublicId}&contextType=${contextType}`
    }),
    addUserRole: build.mutation({
      query: (data) => ({
        url: '/user?action=addUserRole',
        method: 'POST',
        body: data
      })
    }),
    updateSiteUserRole: build.mutation({
      query: (data) => ({
        url: '/user?action=updateSiteUserRole',
        method: 'POST',
        body: data
      })
    }),
    removeUserRole: build.mutation({
      query: (data) => ({
        url: '/user?action=removeUserRole',
        method: 'DELETE',
        body: data
      })
    }),
    inviteGlobalUser: build.mutation({
      query: (data) => ({
        url: '/user?action=inviteGlobalUser',
        method: 'POST',
        body: data
      })
    }),
    inviteBranchUser: build.mutation({
      query: (data) => ({
        url: '/user?action=inviteBranchUser',
        method: 'POST',
        body: data
      })
    }),
    inviteSiteUser: build.mutation({
      query: (data) => ({
        url: '/user?action=inviteSiteUser',
        method: 'POST',
        body: data
      })
    }),
    deleteUser: build.mutation({
      query: (data) => ({
        url: `/user?action=softDeleteUser`,
        method: 'DELETE',
        body: data
      })
    }),
    changePassword: build.mutation({
      query: (data) => ({
        url: `/user?action=changePassword`,
        method: 'POST',
        body: data
      })
    }),
    resetPassword: build.mutation({
      query: (data) => ({
        url: `/user?action=resetPassword`,
        method: 'POST',
        body: data
      })
    }),
    resendTempPassword: build.mutation({
      query: (data) => ({
        url: `/user?action=resendTempPassword`,
        method: 'POST',
        body: data
      })
    }),
    /** End User Functions **/

    /** Start Company Functions **/
    createCompany: build.mutation({
      query: (data) => ({
        url: '/company?action=createCompany',
        method: 'POST',
        body: data
      })
    }),
    getCompanyWithPublicId: build.query({
      query: (publicId: string) => `/company?action=getCompanyById&companyPublicId=${publicId}`
    }),
    getCompanies: build.query({
      query: ({ qty, page }: { qty: string; page: string }) => `/company?action=getCompanies&qty=${qty}&page=${page}`
    }),
    updateCompany: build.mutation({
      query: (data) => ({
        url: '/company?action=updateCompany',
        method: 'PUT',
        body: data
      })
    }),
    deleteCompany: build.mutation({
      query: (publicId: string) => ({
        url: `/company?action=deleteCompany&companyPublicId=${publicId}`,
        method: 'DELETE'
      })
    }),
    deleteCompanyBatch: build.mutation({
      query: (data) => ({
        url: '/company?action=deleteCompanyBatch',
        method: 'DELETE',
        body: data
      })
    }),
    /** End Company Functions **/

    /** Start Branch Functions **/
    createBranch: build.mutation({
      query: (data) => ({
        url: '/branch?action=createBranch',
        method: 'POST',
        body: data
      })
    }),
    getBranchWithPublicId: build.query({
      query: (publicId: string) => `/branch?action=getBranchById&branchPublicId=${publicId}`
    }),
    getBranchWithC2Id: build.query({
      query: (c2Id: string) => `/branch?action=getBranchByC2Id&c2BranchId=${c2Id}`
    }),
    getBranchesWithCompanyPublicId: build.query({
      query: (publicId: string) => `/branch?action=getBranchesWithCompanyPublicId&companyPublicId=${publicId}`
    }),
    getBranches: build.query({
      query: ({ qty, page }: { qty: string; page: string }) => `/branch?action=getAllBranches&qty=${qty}&page=${page}`
    }),
    updateBranch: build.mutation({
      query: (data) => ({
        url: '/branch?action=updateBranch',
        method: 'PUT',
        body: data
      })
    }),
    deleteBranch: build.mutation({
      query: (publicId: string) => ({
        url: `/branch?action=deleteBranch&branchPublicId=${publicId}`,
        method: 'DELETE'
      })
    }),
    /** End Branch Functions **/

    /** Start Site Functions **/
    createSite: build.mutation({
      query: (data) => ({
        url: '/site?action=createSite',
        method: 'POST',
        body: data
      })
    }),
    getSiteWithPublicId: build.query({
      query: (publicId: string) => `/site?action=getSiteById&sitePublicId=${publicId}`
    }),
    getSites: build.query({
      query: () => '/site?action=getAllSites'
    }),
    updateSite: build.mutation({
      query: (data) => ({
        url: '/site?action=updateSite',
        method: 'PUT',
        body: data
      })
    }),
    deleteSite: build.mutation({
      query: (publicId: string) => ({
        url: `/site?action=deleteSite&sitePublicId=${publicId}`,
        method: 'DELETE'
      })
    }),
    /** End Site Functions **/

    /** Start Device Functions **/
    createDevice: build.mutation({
      query: (data) => ({
        url: '/device?action=createDevice',
        method: 'POST',
        body: data
      })
    }),
    getDeviceWithPublicId: build.query({
      query: (publicId) => `/device?action=getDeviceById&devicePublicId=${publicId}`
    }),
    getDeviceListWithSitePublicId: build.query({
      query: ({ qty, page, sitePublicId }) =>
        `/device?action=getAllDevices&sitePublicId=${sitePublicId}&qty=${qty}&page=${page}`
    }),
    updateDevice: build.mutation({
      query: (data) => ({
        url: `/device?action=updateDevice&devicePublicId=${data.device.publicId}`,
        method: 'PUT',
        body: data
      })
    }),
    batchUpdateDevices: build.mutation({
      query: (data) => ({
        url: `/device?action=batchUpdateDevices`,
        method: 'PUT',
        body: data
      })
    }),
    deleteDevice: build.mutation({
      query: (publicId: string) => ({
        url: `/device?action=deleteDevice&devicePublicId=${publicId}`,
        method: 'DELETE'
      })
    }),
    createConfigFile: build.mutation({
      query: (data) => ({
        url: `/device?action=createConfig`,
        method: 'POST',
        body: data
      })
    }),
    createConfigFiles: build.mutation({
      query: (data) => ({
        url: `/device?action=createConfigAll`,
        method: 'POST',
        body: data
      })
    }),
    updateSyncedDate: build.mutation({
      query: (data) => ({
        url: `/device?action=updateSyncedDate&devicePublicId=${data.device.publicId}`,
        method: 'PUT',
        body: data
      })
    }),
    fetchCustomSounds: build.mutation({
      query: (data) => ({
        url: `/device?action=getCustomSoundListById&devicePublicId=${data.device.devicePublicId}`,
        method: 'PUT',
        body: data
      })
    }),
    /** End Device Functions **/

    /** Start App Functions **/
    createApp: build.mutation({
      query: (data) => ({
        url: '/app?action=createApp',
        method: 'POST',
        body: data
      })
    }),
    batchCreateApps: build.mutation({
      query: (data) => ({
        url: '/app?action=batchCreateApps',
        method: 'POST',
        body: data
      })
    }),
    updateApp: build.mutation({
      query: (data) => ({
        url: `/app?action=updateApp&appPublicId=${data.appPublicId}`,
        method: 'PUT',
        body: data
      })
    }),
    batchUpdateApps: build.mutation({
      query: (data) => ({
        url: '/app?action=batchUpdateApps',
        method: 'PUT',
        body: data
      })
    }),
    getAppWithSiteId: build.query({
      query: ({ qty, page, sitePublicId }) =>
        `/app?action=getAppBySiteId&sitePublicId=${sitePublicId}&qty=${qty}&page=${page}`
    }),
    /** End App Functions **/

    /** Start Building Functions **/
    createBuilding: build.mutation({
      query: (data) => ({
        url: '/building?action=createBuilding',
        method: 'POST',
        body: data
      })
    }),

    getBuildings: build.query({
      query: (sitePublicId) => `/building?action=getBuildingBySiteId&sitePublicId=${sitePublicId}`
    }),
    getBuildingsList: build.query({
      query: (sitePublicId) => `/buildings/list?sitePublicId=${sitePublicId}`
    }),

    /** Start Unit Functions */
    createUnit: build.mutation({
      query: (data) => ({
        url: '/unit?action=createUnit',
        method: 'POST',
        body: data
      })
    }),
    createUnitsWizard: build.mutation({
      query: (data) => ({
        url: `/unit?action=wizardCreateUnits`,
        method: 'POST',
        body: data
      })
    }),
    getUnitListWithBuildingPublicId: build.query({
      query: (buildingPublicId) => `/unit?action=getUnitListWithBuildingId&buildingPublicId=${buildingPublicId}`
    }),
    getUnitListWithSitePublicId: build.query({
      query: ({ qty, page, sitePublicId }) =>
        `/unit?action=getUnitListWithSiteId&sitePublicId=${sitePublicId}&qty=${qty}&page=${page}`
    }),
    updateUnit: build.mutation({
      query: (data) => ({
        url: `/unit?action=updateUnit`,
        method: 'PUT',
        body: data
      })
    }),
    deleteUnit: build.mutation({
      query: (publicId: string) => ({
        url: `/unit?action=deleteUnit&unitPublicId=${publicId}`,
        method: 'DELETE'
      })
    }),
    moveOut: build.mutation({
      query: (data) => ({
        url: '/unit?action=moveOut',
        method: 'POST',
        body: data
      })
    }),
    /** End Unit Functions */

    /** Start Tenant Functions */

    createTenant: build.mutation({
      query: (data) => ({
        url: '/tenant?action=createTenant',
        method: 'POST',
        body: data
      })
    }),
    getTenantListWithSiteId: build.query({
      query: ({ qty, page, sitePublicId }) =>
        `/tenant?action=getTenantListWithSiteId&sitePublicId=${sitePublicId}&perPage=${qty}&pageNumber=${page}`
    }),
    deleteTenant: build.mutation({
      query: (publicId: string) => ({
        url: `/tenant?action=deleteTenant&tenantPublicId=${publicId}`,
        method: 'DELETE'
      })
    }),
    updateTenant: build.mutation({
      query: (data) => ({
        url: '/tenant?action=updateTenant',
        method: 'PUT',
        body: data
      })
    }),
    /** End Tenant Functions */

    /** Gateway Command */
    handleGatewayCommand: build.mutation({
      query: (data) => ({
        url: '/v1/gateway',
        method: 'POST',
        body: data
      }),
      extraOptions: {
        timeout: 300000 // 5 minutes
      }
    }),

    /* remoteManagement utilities routes */
    getRemoteManagementUtil: build.mutation({
      query: (data) => ({
        url: '/remoteManagementUtils?action=getLocationByZipCode',
        method: 'POST',
        body: data
      })
    })
  }),

  keepUnusedDataFor: 3 * 60
});

// User Exports
export const {
  useCreateUserMutation,
  useGetUserWithPublicIdQuery,
  useLazyGetUserWithPublicIdQuery,
  useGetUserAttrQuery,
  useGetUsersBySitePublicIdQuery,
  useGetUsersByBranchPublicIdQuery,
  useGetUsersWithNoBranchRolesQuery,
  useGetUsersWithBranchRolesQuery,
  useGetUsersWithGlobalRolesQuery,
  useUpdateUserMutation,
  useAdminUpdateUserMutation,
  useGetAssignableRolesQuery,
  useLazyGetAssignableRolesQuery,
  useAddUserRoleMutation,
  useUpdateSiteUserRoleMutation,
  useRemoveUserRoleMutation,
  useInviteGlobalUserMutation,
  useInviteBranchUserMutation,
  useInviteSiteUserMutation,
  useDeleteUserMutation,
  useChangePasswordMutation,
  useResetPasswordMutation,
  useResendTempPasswordMutation
} = aiphoneCloudApi;

// Company Exports
export const {
  useCreateCompanyMutation,
  useGetCompanyWithPublicIdQuery,
  useGetCompaniesQuery,
  useUpdateCompanyMutation,
  useDeleteCompanyMutation,
  useDeleteCompanyBatchMutation
} = aiphoneCloudApi;

// Branch Exports
export const {
  useCreateBranchMutation,
  useGetBranchWithPublicIdQuery,
  useLazyGetBranchWithPublicIdQuery,
  useLazyGetBranchWithC2IdQuery,
  useGetBranchesWithCompanyPublicIdQuery,
  useGetBranchesQuery,
  useUpdateBranchMutation,
  useDeleteBranchMutation
} = aiphoneCloudApi;

//Site Exports
export const {
  useCreateSiteMutation,
  useGetSiteWithPublicIdQuery,
  useGetSitesQuery,
  useUpdateSiteMutation,
  useDeleteSiteMutation
} = aiphoneCloudApi;

// Device Exports
export const {
  useCreateDeviceMutation,
  useGetDeviceWithPublicIdQuery,
  useGetDeviceListWithSitePublicIdQuery,
  useLazyGetDeviceListWithSitePublicIdQuery,
  useUpdateDeviceMutation,
  useBatchUpdateDevicesMutation,
  useDeleteDeviceMutation,
  useCreateConfigFileMutation,
  useCreateConfigFilesMutation,
  useUpdateSyncedDateMutation,
  useFetchCustomSoundsMutation
} = aiphoneCloudApi;

// App Exports
export const {
  useCreateAppMutation,
  useBatchCreateAppsMutation,
  useUpdateAppMutation,
  useBatchUpdateAppsMutation,
  useGetAppWithSiteIdQuery,
  useLazyGetAppWithSiteIdQuery
} = aiphoneCloudApi;

// Building Exports
export const {
  useCreateBuildingMutation,
  useGetBuildingsQuery,
  useGetBuildingsListQuery,
  useLazyGetBuildingsQuery,
  useLazyGetBuildingsListQuery
} = aiphoneCloudApi;

// Tenant Exports
export const {
  useCreateTenantMutation,
  useGetTenantListWithSiteIdQuery,
  useLazyGetTenantListWithSiteIdQuery,
  useDeleteTenantMutation,
  useUpdateTenantMutation
} = aiphoneCloudApi;

// Unit Exports
export const {
  useCreateUnitsWizardMutation,
  useCreateUnitMutation,
  useLazyGetUnitListWithBuildingPublicIdQuery,
  useLazyGetUnitListWithSitePublicIdQuery,
  useGetUnitListWithBuildingPublicIdQuery,
  useGetUnitListWithSitePublicIdQuery,
  useUpdateUnitMutation,
  useDeleteUnitMutation,
  useMoveOutMutation
} = aiphoneCloudApi;

// Gateway Command Exports
export const { useHandleGatewayCommandMutation } = aiphoneCloudApi;

// Remote Management Util Exports
export const { useGetRemoteManagementUtilMutation } = aiphoneCloudApi;
