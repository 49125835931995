import { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Link,
  Button,
  Tooltip
} from '@mui/material';
import { Field, Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { GridValidRowModel } from '@mui/x-data-grid';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { useCreateDeviceMutation, useLazyGetUnitListWithSitePublicIdQuery } from 'services/aiphoneCloud';
import { LoadingButton } from '@mui/lab';
import { Link as RouterLink } from 'react-router-dom';
import { SearchDevice } from '../Types';
import StationSearchDataGrid from '../NewSiteWizard/steps/AddDevices/components/datagrids/StationSearchDataGrid';
import {
  answeringStationTypes,
  buildDevicePayload,
  countDevicesByType,
  doorStationTypes,
  entranceStationTypes,
  unitTypeAnsweringStationLimits,
  unitTypeDoorStationLimits,
  unitTypeEntranceStationLimits
} from 'shared/utils/removeManagementHelperFunctions';
import AddDeviceManuallyDataGrid from 'features/RemoteManagement/NewSiteWizard/steps/AddDevices/components/datagrids/AddDeviceManuallyDataGrid';
import { useTranslation } from 'react-i18next';

interface IDialogWindowProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  setShouldFetchDevices: (shouldFetchDevices: boolean) => void;
  deviceTypeFilter?: string[];
  isUnitRequired?: boolean;
  isBuildingRequired?: boolean;
}

const AddDeviceDialog = ({
  isOpen,
  setIsOpen,
  deviceTypeFilter,
  setShouldFetchDevices,
  isUnitRequired = false,
  isBuildingRequired = false
}: IDialogWindowProps) => {
  const MAX_ALLOWED_LC_PER_BUILDING = 16;

  const site = useSelector((state: RootState) => state.site);
  const unitsList = useSelector((state: RootState) => state.units.UnitList);
  const buildingList = useSelector((state: RootState) => state.buildings.BuildingList);
  const devices = useSelector((state: RootState) => state.devices);
  const isGatewayRegistered =
    site.siteInfo.registeredGatewayPublicId !== null || devices.DeviceListByType['GatewayAdaptor'].length > 0;
  const [isSaving, setIsSaving] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState<'success' | 'error'>('success');
  const [isAddingManually, setIsAddingManually] = useState(!isGatewayRegistered); //if gateway is not registered, default to manual
  const [manualDevices, setManualDevices] = useState<GridValidRowModel[]>([]);
  const [searchDevices, setSearchDevices] = useState<SearchDevice[]>([]);
  const [isAtDeviceLimit, setIsAtDeviceLimit] = useState(false);
  const [maxStationCountsForUnit, setMaxStationCountsForUnit] = useState<{ [key: string]: number }>({});
  const [currentStationCountsForUnit, setCurrentStationCountsForUnit] = useState<{ [key: string]: number }>({});
  const [allowedLcCount, setAllowedLcCount] = useState(0);
  const [selectedBuildingNumber, setSelectedBuildingNumber] = useState<string | undefined>(undefined);
  const [existingSavedLcCount, setExistingSavedLcCount] = useState(0);
  const [isMaxLcReached, setIsMaxLcReached] = useState(false);
  const [createDevice] = useCreateDeviceMutation();
  const [fetchUnits] = useLazyGetUnitListWithSitePublicIdQuery();

  const [restrictedMacAddresses, setRestrictedMacAddresses] = useState<string[]>(['00:00:00:00:00:00']);
  const [devicesResetTrigger, setDevicesResetTrigger] = useState(0);

  const { t } = useTranslation();

  useEffect(() => {
    const deviceList = devices.DeviceList;
    // set all devices that are already in the site to be added to restricted mac addresses
    const restrictedMacAddressList = Object.values(deviceList).map((device) => device.basicInfo.macAddress);
    setRestrictedMacAddresses(restrictedMacAddressList);
  }, [devices.DeviceList]);

  // calculate the existing lift control devices in the building
  useEffect(() => {
    const liftControl = devices?.DeviceListByType['LiftControl'] || [];
    const deviceList = devices.DeviceList;

    if (liftControl.length > 0) {
      const lcCountArray = liftControl.reduce((acc: string[], uuid: string) => {
        if (deviceList[uuid]?.buildingPublicId === selectedBuildingNumber) {
          acc.push(uuid);
        }
        return acc;
      }, []);
      setExistingSavedLcCount(lcCountArray.length);
      setAllowedLcCount(MAX_ALLOWED_LC_PER_BUILDING - lcCountArray.length);
    }
  }, [selectedBuildingNumber]);

  useEffect(() => {
    if (selectedBuildingNumber) {
      // check the limit of lift control devices
      isMaxLiftControlReached();
    }
  }, [searchDevices, manualDevices]);

  const generateUnitOptions = (selectedBuildingPublicId: string) => {
    return Object.entries(unitsList)
      .filter(([_, value]) => value.buildingPublicId === selectedBuildingPublicId)
      .sort((a, b) => Number(a[1].unitNumber) - Number(b[1].unitNumber)) // Sort units by unitNumber
      .map(([key, value]) => ({ value: key, label: value.unitNumber }));
  };

  const buildingOptions = Object.entries(buildingList)
    .map(([key, value]) => ({
      value: key,
      label: value.buildingNumber
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const validationSchema = Yup.object().shape({
    buildingNumber: Yup.string().required(t('Building_Number_is_required')),
    unitNumber: isUnitRequired ? Yup.string().required(t('Unit_is_required')) : Yup.string(),
    selectedDevices: Yup.array().min(1, t('At_least_one_device_must_be_selected'))
  });

  /**
   * Checks if the maximum number of Lift Controls per building has been reached.
   */
  const isMaxLiftControlReached = () => {
    const totalAddedDevices = [...manualDevices, ...searchDevices];
    const liftControlCount = totalAddedDevices.filter((device) => device.model_number === 'IXGW-LC').length;
    const remainingLcCount = MAX_ALLOWED_LC_PER_BUILDING - existingSavedLcCount - liftControlCount;
    setAllowedLcCount(remainingLcCount);
    setIsMaxLcReached(remainingLcCount < 0);
  };

  const calculateAllowedLcCount = (liftControl: string[]) => {
    const deviceList = devices.DeviceList;

    const lcCountArray = liftControl.reduce((acc: string[], uuid: string) => {
      if (deviceList[uuid]?.buildingPublicId === selectedBuildingNumber) {
        acc.push(uuid);
      }
      return acc;
    }, []);
    setAllowedLcCount(MAX_ALLOWED_LC_PER_BUILDING - existingSavedLcCount - lcCountArray.length);
  };

  const handleAddDevices = async (values: { buildingNumber: string; unitNumber: string }) => {
    const { buildingNumber, unitNumber } = values;

    const devicesToAdd = buildDevicePayload(
      [...manualDevices, ...searchDevices],
      isUnitRequired,
      isBuildingRequired,
      buildingNumber,
      unitNumber
    );
    const params = {
      sitePublicId: site.siteInfo.publicId,
      devices: devicesToAdd
    };

    try {
      setIsSaving(true);
      // return; // Remove this line when TESTING is done
      const response = await createDevice(params);
      if (response.data && response.data.length > 0) {
        setAlertSeverity('success');
        if (response.data.length === 1) {
          setAlertMessage(t('Device_Added_Successfully'));
        } else if (response.data.length > 1) {
          setAlertMessage(t('Devices_Added_Successfully'));
        } else {
          setAlertMessage(t('Device_s_Added_Successfully'));
        }
        setShouldFetchDevices(true);
        fetchUnits({
          sitePublicId: site.siteInfo.publicId,
          qty: -1,
          page: 0
        });
        setTimeout(() => {
          setAlertOpen(false);
          setIsOpen(false);
          setManualDevices([]);
          setSearchDevices([]);
          setIsAddingManually(!isGatewayRegistered);
        }, 2000);
      } else {
        setAlertSeverity('error');
        setAlertMessage(t('Failed_to_add_device_s'));
      }
      setAlertOpen(true);
      setIsSaving(false);
    } catch (error) {
      setAlertSeverity('error');
      setAlertMessage(t('Error_adding_device_s'));
      setAlertOpen(true);
      setIsSaving(false);
    }
  };

  const handleCloseAlert = () => {
    setAlertOpen(false);
  };

  const handleDialogClose = () => {
    setIsOpen(false);
    setAlertMessage('');
    setAlertOpen(false);
    setIsAtDeviceLimit(false);
    setManualDevices([]);
    setSearchDevices([]);
    setIsAddingManually(!isGatewayRegistered);
    calculateAllowedLcCount([]);
  };

  const checkDeviceLimit = (e: any, formikProps: FormikProps<any>) => {
    //Reset the datagrid
    setSearchDevices([]);
    setManualDevices([]);
    setDevicesResetTrigger((prev) => prev + 1);

    const unitPublicId = e.target.value;
    const unitType = unitsList[unitPublicId].unitType;
    const unitDevices = unitsList[unitPublicId].devicePublicIds || [];

    const maxStationCountsForUnitTemp = {
      answeringStations: unitTypeAnsweringStationLimits[unitType as number],
      doorStations: unitTypeDoorStationLimits[unitType as number],
      entranceStations: unitTypeEntranceStationLimits[unitType as number]
    };

    setMaxStationCountsForUnit({
      answeringStations: maxStationCountsForUnitTemp.answeringStations,
      doorStations: maxStationCountsForUnitTemp.doorStations,
      entranceStations: maxStationCountsForUnitTemp.entranceStations
    });

    const currentStationCountsForUnitTemp = {
      answeringStations: countDevicesByType(unitDevices, devices.DeviceList, answeringStationTypes),
      doorStations: countDevicesByType(unitDevices, devices.DeviceList, doorStationTypes),
      entranceStations: countDevicesByType(unitDevices, devices.DeviceList, entranceStationTypes)
    };
    setCurrentStationCountsForUnit({
      answeringStations: currentStationCountsForUnitTemp.answeringStations,
      doorStations: currentStationCountsForUnitTemp.doorStations,
      entranceStations: currentStationCountsForUnitTemp.entranceStations
    });

    //TODO: Refactor this function to be more readable RM-501 PR
    const isAtLimit = (current: number, max: number) => current >= max && max !== 0;

    const isDeviceTypeFilterArray = deviceTypeFilter && Array.isArray(deviceTypeFilter);

    switch (isDeviceTypeFilterArray ? deviceTypeFilter[0] : deviceTypeFilter) {
      case 'Video Master Station':
      case 'Sub Master Station':
      case 'Tenant Station':
      case 'Guard Station':
        if (
          isAtLimit(currentStationCountsForUnitTemp.answeringStations, maxStationCountsForUnitTemp.answeringStations)
        ) {
          setIsAtDeviceLimit(true);
        } else {
          setIsAtDeviceLimit(false);
        }
        break;
      case 'Video Door Station':
      case 'Audio Door Station':
      case 'Emergency Station':
      case 'Stainless Steel Audio Door Station':
      case 'Mullion Video Door Station':
      case 'Plastic Video Door Station':
        if (isAtLimit(currentStationCountsForUnitTemp.doorStations, maxStationCountsForUnitTemp.doorStations)) {
          setIsAtDeviceLimit(true);
        } else {
          setIsAtDeviceLimit(false);
        }
        break;
      case 'Entrance Station':
        if (isAtLimit(currentStationCountsForUnitTemp.entranceStations, maxStationCountsForUnitTemp.entranceStations)) {
          setIsAtDeviceLimit(true);
        } else {
          setIsAtDeviceLimit(false);
        }
        break;
      default:
        break;
    }

    formikProps.setFieldValue('unitNumber', unitPublicId).catch();
  };

  return (
    <Dialog
      onClose={handleDialogClose}
      open={isOpen}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="md"
      disableEscapeKeyDown={true}
      fullWidth
    >
      <DialogTitle>{t('Add_Devices')}</DialogTitle>
      <DialogContent>
        {alertOpen && (
          <Alert onClose={handleCloseAlert} severity={alertSeverity} sx={{ width: '100%' }}>
            {alertMessage}
          </Alert>
        )}
        <Formik
          initialValues={{
            buildingNumber: selectedBuildingNumber || '',
            unitNumber: ''
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => handleAddDevices(values)}
        >
          {(formikProps) => {
            return (
              <Form>
                <FormControl fullWidth margin="normal">
                  <InputLabel id="building-number-label">{t('Building_Number')}</InputLabel>
                  <Field
                    as={Select}
                    labelId="building-number-label"
                    label={t('Building_Number')}
                    name="buildingNumber"
                    value={selectedBuildingNumber || ''}
                    onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                      setSelectedBuildingNumber(event.target.value as string);
                      formikProps.setFieldValue('buildingNumber', event.target.value);
                    }}
                  >
                    {buildingOptions.length > 0 ? (
                      buildingOptions.map((building: { value: string; label: string }) => (
                        <MenuItem key={building.value} value={building.value}>
                          {building.label}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled>{t('No_buildings_available')}</MenuItem>
                    )}
                  </Field>
                  {formikProps.touched.buildingNumber && formikProps.errors.buildingNumber && (
                    <Typography variant="caption" color="error">
                      {formikProps.errors.buildingNumber}
                    </Typography>
                  )}
                </FormControl>
                {isUnitRequired && (
                  <FormControl fullWidth margin="normal">
                    <InputLabel id="unit-number-label">{t('Unit_Number')}</InputLabel>
                    <Field
                      as={Select}
                      labelId="unit-number-label"
                      label={t('Unit_Number')}
                      name="unitNumber"
                      variant="outlined"
                      required={isUnitRequired}
                      onChange={(e: any) => checkDeviceLimit(e, formikProps)}
                      disabled={!formikProps.values.buildingNumber}
                    >
                      {generateUnitOptions(formikProps.values.buildingNumber).map((unit: any) => (
                        <MenuItem key={unit.value} value={unit.value}>
                          {unit.label}
                        </MenuItem>
                      ))}
                      {formikProps.values.buildingNumber &&
                        generateUnitOptions(formikProps.values.buildingNumber).length === 0 && (
                          <MenuItem disabled>{t('No_units_available')}</MenuItem>
                        )}
                    </Field>
                    {formikProps.touched.unitNumber && formikProps.errors.unitNumber && (
                      <Typography variant="caption" color="error">
                        {formikProps.errors.unitNumber}
                      </Typography>
                    )}
                    {formikProps.values.buildingNumber &&
                      generateUnitOptions(formikProps.values.buildingNumber).length === 0 && (
                        <Typography variant="caption" color="primary">
                          <Link
                            component={RouterLink}
                            to={`/site/${site.siteInfo.publicId}/units`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {t('Add_Unit')}
                          </Link>
                        </Typography>
                      )}
                  </FormControl>
                )}
                {isMaxLcReached && <Alert severity="warning">{t('Max_LC_Reached')}</Alert>}
                {isAtDeviceLimit && <Alert severity="warning">{t('Unit_device_limit_reached')}</Alert>}
                {isGatewayRegistered ? (
                  <StationSearchDataGrid
                    searchSelectedDevices={searchDevices}
                    setSearchSelectedDevices={setSearchDevices}
                    setIsAddingManually={setIsAddingManually}
                    restrictedMacAddresses={restrictedMacAddresses}
                    setRestrictedMacAddresses={setRestrictedMacAddresses}
                    deviceTypeFilter={deviceTypeFilter}
                    selectedUnitType={unitsList[formikProps.values.unitNumber]?.unitType}
                  />
                ) : (
                  <AddDeviceManuallyDataGrid
                    manualSelectedDevices={manualDevices}
                    setManualSelectedDevices={setManualDevices}
                    restrictedMacAddresses={restrictedMacAddresses}
                    deviceTypeFilter={deviceTypeFilter}
                    allowedLcCount={allowedLcCount}
                    maxStationCountsForUnit={maxStationCountsForUnit}
                    currentStationCountsForUnit={currentStationCountsForUnit}
                    selectedUnitType={unitsList[formikProps.values.unitNumber]?.unitType}
                    devicesResetTrigger={devicesResetTrigger}
                    setIsAtDeviceLimit={setIsAtDeviceLimit}
                  />
                )}
                <Tooltip title={!site.siteInfo.registeredGatewayPublicId ? t('Registered_IXGW_GW_is_required') : null}>
                  <span>
                    <Button
                      sx={styles.dualButtonContainer}
                      color="primary"
                      onClick={() => {
                        setIsAddingManually(!isAddingManually);
                      }}
                      disabled={!isGatewayRegistered}
                    >
                      {isAddingManually ? t('Add_Devices_with_Station_Search') : t('Add_Devices_Manually')}
                    </Button>
                  </span>
                </Tooltip>
                <DialogActions>
                  <LoadingButton onClick={handleDialogClose} color="primary" variant="contained">
                    {t('Button_Cancel')}
                  </LoadingButton>
                  <LoadingButton
                    type="submit"
                    color="primary"
                    variant="contained"
                    loading={isSaving}
                    disabled={
                      (manualDevices.length === 0 && searchDevices.length === 0) ||
                      (isUnitRequired && !formikProps.values.unitNumber) ||
                      !formikProps.values.buildingNumber ||
                      isAtDeviceLimit ||
                      isMaxLcReached
                    }
                  >
                    {t('Add_Device_s')}
                  </LoadingButton>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

const styles = {
  dualButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',
    marginTop: '1rem'
  }
};

export default AddDeviceDialog;
