import React, { useState, useCallback } from 'react';
import {
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
  rectIntersection,
  DragEndEvent,
  DragOverlay
} from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy, arrayMove } from '@dnd-kit/sortable';
import {
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button
} from '@mui/material';

import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import GroupIcon from '@mui/icons-material/Group';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useTranslation } from 'react-i18next';
import { Item, Folder, CallGroup } from './model';
import { generateUniqueId, removeItem, removeItemAndReturnIt, findItemById, insertItem } from './Utils';
import SortableRow from './SortableRow';

// Import your shared styled components
import { PageContainer, ActionButtonsContainer, StyledTableContainer, PrimaryButton } from '../Shared/Common.styled';

// Helper to check blank input
const isBlank = (str: string) => !str || str.trim().length === 0;

const CallGrouping: React.FC = () => {
  const { t } = useTranslation('nextIX');

  const [items, setItems] = useState<Item[]>([]);
  const [expandedFolders, setExpandedFolders] = useState<Record<string, boolean>>({});

  // Dialog states
  const [folderDialogOpen, setFolderDialogOpen] = useState(false);
  const [callGroupDialogOpen, setCallGroupDialogOpen] = useState(false);

  // Input states
  const [newFolderName, setNewFolderName] = useState('');
  const [newCallGroupName, setNewCallGroupName] = useState('');
  const [newCallGroupNumber, setNewCallGroupNumber] = useState('');

  // Validation error states
  const [folderNameError, setFolderNameError] = useState('');
  const [callGroupNameError, setCallGroupNameError] = useState('');
  const [callGroupNumberError, setCallGroupNumberError] = useState('');

  // For drag & drop
  const [activeId, setActiveId] = useState<string | null>(null);
  const sensors = useSensors(useSensor(PointerSensor));

  /** Toggle expand/collapse for a folder */
  const toggleFolder = (folderId: string) => {
    setExpandedFolders((prev) => ({
      ...prev,
      [folderId]: !prev[folderId]
    }));
  };

  // -------------------------------
  // 1) Add Folder
  // -------------------------------
  const handleAddFolder = () => {
    setFolderNameError('');
    if (isBlank(newFolderName)) {
      setFolderNameError(t('callGroup.error_folder_name_required'));
      return;
    }
    const newFolder: Folder = {
      id: generateUniqueId(),
      name: newFolderName.trim(),
      items: [],
      type: 'folder'
    };
    setItems((prev) => [...prev, newFolder]);
    setNewFolderName('');
    setFolderDialogOpen(false);
  };

  // ---------------------------------
  // 2) Add Call Group
  // ---------------------------------
  const handleAddCallGroup = () => {
    setCallGroupNameError('');
    setCallGroupNumberError('');

    let hasError = false;
    if (isBlank(newCallGroupName)) {
      setCallGroupNameError(t('callGroup.error_call_group_name_required'));
      hasError = true;
    }
    if (isBlank(newCallGroupNumber)) {
      setCallGroupNumberError(t('callGroup.error_call_group_number_required'));
      hasError = true;
    }
    if (hasError) return;

    const newCallGroup: CallGroup = {
      id: generateUniqueId(),
      name: newCallGroupName.trim(),
      number: newCallGroupNumber.trim(),
      type: 'callGroup'
    };
    setItems((prev) => [...prev, newCallGroup]);
    setNewCallGroupName('');
    setNewCallGroupNumber('');
    setCallGroupDialogOpen(false);
  };

  // ---------------------------------
  // 3) Delete Item
  // ---------------------------------
  const handleDeleteItem = (id: string) => {
    setItems((prev) => removeItem(prev, id));
  };

  // ---------------------------------
  // 4) Drag End
  // ---------------------------------
  const handleDragEnd = useCallback(
    ({ active, over }: DragEndEvent) => {
      if (!over) return;
      const activeIdStr = String(active.id);
      const overIdStr = String(over.id);
      if (activeIdStr === overIdStr) return;

      const [withoutActive, draggedItem] = removeItemAndReturnIt(items, activeIdStr);
      if (!draggedItem) return;

      const { item: overItem } = findItemById(withoutActive, overIdStr);
      if (overItem && overItem.type === 'folder') {
        setItems(insertItem(withoutActive, overIdStr, draggedItem));
      } else {
        const activeIndex = items.findIndex((i) => i.id === activeIdStr);
        const overIndex = items.findIndex((i) => i.id === overIdStr);
        if (activeIndex !== -1 && overIndex !== -1) {
          setItems(arrayMove(items, activeIndex, overIndex));
        } else {
          setItems([...withoutActive, draggedItem]);
        }
      }
      setActiveId(null);
    },
    [items]
  );

  const renderItems = (someItems: Item[], level: number) => {
    return (
      <SortableContext items={someItems.map((i) => i.id)} strategy={verticalListSortingStrategy}>
        {someItems.map((item) => (
          <React.Fragment key={item.id}>
            <SortableRow id={item.id}>
              <TableCell sx={{ pl: level * 4 }}>
                <Box display="flex" alignItems="center">
                  {item.type === 'folder' ? (
                    <>
                      <IconButton size="small" onClick={() => toggleFolder(item.id)} sx={{ mr: 1 }}>
                        {expandedFolders[item.id] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </IconButton>
                      <FolderOpenIcon sx={{ mr: 1 }} />
                      <Typography>{item.name}</Typography>
                    </>
                  ) : (
                    <>
                      <GroupIcon sx={{ mr: 1 }} />
                      <Typography>{item.name}</Typography>
                    </>
                  )}
                </Box>
              </TableCell>
              <TableCell>{item.type === 'callGroup' ? item.number : t('callGroup.folder')}</TableCell>
              <TableCell>
                <IconButton size="small" color="error" onClick={() => handleDeleteItem(item.id)}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </SortableRow>

            {/* Sub-items if folder is expanded */}
            {item.type === 'folder' && expandedFolders[item.id] && item.items.length > 0 && (
              <>{renderItems(item.items, level + 1)}</>
            )}
          </React.Fragment>
        ))}
      </SortableContext>
    );
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={rectIntersection}
      onDragStart={({ active }) => setActiveId(String(active.id))}
      onDragEnd={handleDragEnd}
    >
      <PageContainer>
        <ActionButtonsContainer>
          {/* Use your PrimaryButton or normal MUI Button */}
          <PrimaryButton variant="contained" onClick={() => setFolderDialogOpen(true)}>
            {t('callGroup.add_folder')}
          </PrimaryButton>
          <PrimaryButton variant="contained" onClick={() => setCallGroupDialogOpen(true)}>
            {t('callGroup.add_call_group')}
          </PrimaryButton>
        </ActionButtonsContainer>

        <Paper>
          <StyledTableContainer>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {/* This blank cell is for the folder expand/collapse icon area */}
                  <TableCell />
                  <TableCell>{t('callGroup.name')}</TableCell>
                  <TableCell>{t('callGroup.type_number')}</TableCell>
                  <TableCell>{t('callGroup.actions')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{renderItems(items, 0)}</TableBody>
            </Table>
          </StyledTableContainer>

          <DragOverlay>
            {activeId && (
              <Box
                sx={{
                  p: 1,
                  bgcolor: '#d3d3d3',
                  border: '1px solid #ccc',
                  borderRadius: 2
                }}
              >
                <strong>{items.find((i) => i.id === activeId)?.name}</strong>
              </Box>
            )}
          </DragOverlay>
        </Paper>
      </PageContainer>

      {/* Dialog: Add Folder */}
      <Dialog open={folderDialogOpen} onClose={() => setFolderDialogOpen(false)}>
        <DialogTitle>{t('callGroup.dialog_add_folder')}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label={t('callGroup.folder_name')}
            type="text"
            fullWidth
            value={newFolderName}
            onChange={(e) => setNewFolderName(e.target.value)}
            error={!!folderNameError}
            helperText={folderNameError || ''}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setFolderDialogOpen(false)}>{t('callGroup.cancel')}</Button>
          <Button onClick={handleAddFolder}>{t('callGroup.confirm')}</Button>
        </DialogActions>
      </Dialog>

      {/* Dialog: Add Call Group */}
      <Dialog open={callGroupDialogOpen} onClose={() => setCallGroupDialogOpen(false)}>
        <DialogTitle>{t('callGroup.dialog_add_call_group')}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label={t('callGroup.call_group_name')}
            type="text"
            fullWidth
            value={newCallGroupName}
            onChange={(e) => setNewCallGroupName(e.target.value)}
            error={!!callGroupNameError}
            helperText={callGroupNameError || ''}
          />
          <TextField
            margin="dense"
            label={t('callGroup.calling_number')}
            type="text"
            fullWidth
            value={newCallGroupNumber}
            onChange={(e) => setNewCallGroupNumber(e.target.value)}
            error={!!callGroupNumberError}
            helperText={callGroupNumberError || ''}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCallGroupDialogOpen(false)}>{t('callGroup.cancel')}</Button>
          <Button onClick={handleAddCallGroup}>{t('callGroup.confirm')}</Button>
        </DialogActions>
      </Dialog>
    </DndContext>
  );
};

export default CallGrouping;
