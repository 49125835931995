import { Item, Folder } from './model';

// Unique ID generator
let id = Date.now();
export function generateUniqueId() {
  return `unique-${id++}`;
}

/**
 * removeItem
 * - Returns a new array without the item that has id = `targetId`.
 * - Recursively searches folders to remove nested items.
 */
export function removeItem(items: Item[], targetId: string): Item[] {
  return items.reduce<Item[]>((acc, item) => {
    if (item.id === targetId) {
      return acc; // skip
    }
    if (item.type === 'folder') {
      const updatedItems = removeItem(item.items, targetId);
      acc.push({ ...item, items: updatedItems });
    } else {
      acc.push(item);
    }
    return acc;
  }, []);
}

/**
 * removeItemAndReturnIt
 * - Removes an item by ID and returns [updatedTree, removedItem].
 */
export function removeItemAndReturnIt(items: Item[], itemId: string): [Item[], Item | null] {
  let removedItem: Item | null = null;
  const newTree = items.reduce<Item[]>((acc, node) => {
    if (node.id === itemId) {
      removedItem = node;
      return acc; // skip
    }
    if (node.type === 'folder') {
      const [updatedSub, subRemoved] = removeItemAndReturnIt(node.items, itemId);
      if (subRemoved) removedItem = subRemoved;
      acc.push({ ...node, items: updatedSub });
    } else {
      acc.push(node);
    }
    return acc;
  }, []);
  return [newTree, removedItem];
}

/**
 * findItemById
 * - Locates an item (or folder) in a nested structure by ID.
 *   Returns { parent: Folder | null, item: Item | null }.
 */
export function findItemById(tree: Item[], id: string): { parent: Folder | null; item: Item | null } {
  for (const node of tree) {
    if (node.id === id) {
      return { parent: null, item: node };
    }
    if (node.type === 'folder') {
      const subResult = findItemById(node.items, id);
      if (subResult.item) {
        return { parent: node, item: subResult.item };
      }
    }
  }
  return { parent: null, item: null };
}

/**
 * insertItem
 * - Inserts `itemToInsert` into folder with ID = `folderId`.
 */
export function insertItem(items: Item[], folderId: string, itemToInsert: Item): Item[] {
  return items.map((node) => {
    if (node.id === folderId && node.type === 'folder') {
      return { ...node, items: [...node.items, itemToInsert] };
    }
    if (node.type === 'folder') {
      return {
        ...node,
        items: insertItem(node.items, folderId, itemToInsert)
      };
    }
    return node;
  });
}
