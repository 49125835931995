import { useSelector } from 'react-redux';
import { RootState } from 'store';
import MultiTenantSiteWizard from './MultiTenantSiteWizard';
import CommercialSiteWizard from './CommercialSiteWizard';
import { useEffect, useState } from 'react';
import { getCurrentUser } from 'store/slices/usersSlice';
import { UserInfo } from 'shared/utils/UserUtils';

const SetupWizard = () => {
  const [isRegistered, setIsRegistered] = useState(false);
  const siteData = useSelector((state: RootState) => state.site.siteInfo);
  const isGatewayInDeviceByType = useSelector((state: RootState) => state.devices.DeviceListByType['GatewayAdaptor']);

  // Use this to get the email address of the user.
  // We will then check if it contains the @aiphone.com suffix
  const userObj = useSelector(getCurrentUser);
  const userInfo = new UserInfo(userObj);
  const emailAddress = userInfo.email;

  useEffect(() => {
    if (siteData.registeredGatewayPublicId || isGatewayInDeviceByType.length > 0) {
      // We need to check here to see if the site is registered and the user is not an @aiphone.com email
      // If the user does not have an @aiphone.com email address, we should redirect them back.
      if (!emailAddress.includes('@aiphone.com')) {
        window.history.back();
        return;
      }
      setIsRegistered(true);
    }
  }, [siteData]);

  if (siteData.typeId === 1) {
    return <CommercialSiteWizard isRegistered={isRegistered} setIsRegistered={setIsRegistered} />;
  } else {
    return <MultiTenantSiteWizard isRegistered={isRegistered} setIsRegistered={setIsRegistered} />;
  }
};

export default SetupWizard;
