import { styled } from '@mui/material/styles';
import {
  Box,
  Typography,
  IconButton,
  Button,
  TableContainer,
  Paper, TableRow
} from '@mui/material';


/* -------------------------------------
 * SHARED CALENDAR STYLES
 * ------------------------------------- */

/** Main container for the calendar */
export const CalendarContainer = styled(Box)(({ theme }) => ({
  height: '80vh',
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
  backgroundColor: theme.palette.background.paper,
}));

/** Toolbar container for the calendar header */
export const ToolbarContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
  padding: theme.spacing(1),
  backgroundColor: theme.palette.common.white,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[1],
}));

/** Icon button specifically for calendar navigation */
export const CalendarNavIconButton = styled(IconButton)(({ theme, position }) => ({
  ...(position === 'left' && { marginLeft: theme.spacing(2) }),
  ...(position === 'right' && { marginRight: theme.spacing(2) }),
}));

/** Toolbar label (date label) */
export const ToolbarLabel = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  marginInline: theme.spacing(2),
  textAlign: 'center',
  fontSize: '1.25rem',
}));

/** Buttons for changing calendar views (Month, Week, Day) */
export const CalendarViewButton = styled(Button)(({ theme }) => ({
  marginRight: theme.spacing(1),
}));


/* -------------------------------------
 * SHARED DEVICE-SETTINGS STYLES
 * ------------------------------------- */

/** Container for the device settings screen */
export const DeviceSettingsContainer = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
  width: '80%',
  margin: '0 auto'
}));

/** Title at the top (e.g. "Device Settings") */
export const DeviceSettingsTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  ...theme.typography.h4,
  fontWeight: 'bold',
  textAlign: 'center',
}));

/** Container for the "Add Device" button */
export const AddDeviceButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  marginBottom: theme.spacing(2),
  color: theme.palette.primary.main,
}));

/** Table container with sticky header + max height */
export const StyledTableContainer = styled(TableContainer)(() => ({
  maxHeight: 440,
}));

/** Paper for wrapping the table */
export const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.grey[100],
}));

/** Icon button used in the device settings table rows */
export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.main,
  margin: theme.spacing(1)
}));


export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  }
}));

/** Wrap the main page content (e.g., your entire CallGrouping) */
export const PageContainer = styled(Box)(({ theme }) => ({
  margin: theme.spacing(2),
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
}));

/** A row of action buttons with consistent spacing */
export const ActionButtonsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  marginBottom: theme.spacing(3),
}));


/** Example of a custom styled button if needed */
export const PrimaryButton = styled(Button)(({ theme }) => ({
  // Example override
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));