import { Box, Container } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { getSelectedDevice, IDevice } from 'store/slices/devicesSlice';
import { SoundsSection } from './SoundSection';

// Assuming DeviceVolumeComponentType is defined in a file named 'types.ts' in the same directory

interface SoundsProps {
  device: IDevice;
}

export const VolumeSoundSettings: React.FC<SoundsProps> = ({ device }) => {
  const { deviceSettings } = useSelector(getSelectedDevice);
  if (!deviceSettings) return null;

  const answeringSettingItems = [
    {
      sectionTitle: 'Speaker',
      settings: [
        {
          settingItemTitle: 'Microphone Volume Speaker',
          settingItemDescription: 'Volume of the outgoing microphone speaker',
          defaultValue: deviceSettings.volumeOutgoingSpeaker,
          type: 'slider',
          name: 'volumeOutgoingSpeaker'
        },
        {
          settingItemTitle: 'Volume Incoming Speaker',
          settingItemDescription: 'Volume of the incoming speaker',
          defaultValue: deviceSettings.volumeIncomingSpeaker,
          type: 'slider',
          name: 'volumeIncomingSpeaker'
        },
        {
          settingItemTitle: 'Volume External Speaker',
          settingItemDescription: 'Volume of the external speaker',
          defaultValue: deviceSettings.volumeExternalSpeaker,
          type: 'slider',
          name: 'volumeExternalSpeaker'
        }
      ]
    },
    {
      sectionTitle: 'Output Sound',
      settings: [
        {
          settingItemTitle: 'Incoming Call Ringtone Volume',
          settingItemDescription: 'Volume of the incoming call ringtone',
          defaultValue: deviceSettings.volumeRingtone,
          type: 'slider',
          name: 'volumeRingtone'
        },
        {
          settingItemTitle: 'External Microphone Volume',
          settingItemDescription: 'Volume of the external microphone',
          defaultValue: deviceSettings.volumeExternalMicrophone,
          type: 'slider',
          name: 'volumeExternalMicrophone'
        },
        {
          settingItemTitle: 'Button Feedback Tone',
          settingItemDescription: 'Permits a tone to be played on the speaker when a button is pressed',
          defaultValue: deviceSettings.volumeOperation,
          type: 'switch',
          name: 'volumeOperation'
        }
      ]
    },
    {
      sectionTitle: 'Handset',
      settings: [
        {
          settingItemTitle: 'Volume Outgoing Handset',
          settingItemDescription: 'Volume of the outgoing handset',
          defaultValue: deviceSettings.volumeOutgoingHandset,
          type: 'slider',
          name: 'volumeOutgoingHandset'
        },
        {
          settingItemTitle: 'Volume Incoming Handset',
          settingItemDescription: 'Volume of the incoming handset',
          defaultValue: deviceSettings.volumeIncomingHandset,
          type: 'slider',
          name: 'volumeIncomingHandset'
        }
      ]
    }
  ];

  const tenantStationSettingItems = [
    {
      sectionTitle: 'Speaker',
      settings: [
        {
          settingItemTitle: 'Microphone Volume Speaker',
          settingItemDescription: 'Adjust the volume of the outgoing microphone speaker',
          defaultValue: deviceSettings.volumeOutgoingSpeaker,
          type: 'slider',
          name: 'volumeOutgoingSpeaker'
        },
        {
          settingItemTitle: 'Volume Incoming Speaker',
          settingItemDescription: 'Volume of the incoming speaker',
          defaultValue: deviceSettings.volumeIncomingSpeaker,
          type: 'slider',
          name: 'volumeIncomingSpeaker'
        },
        {
          settingItemTitle: 'Incoming Call Ringtone Volume',
          settingItemDescription: 'Volume of the incoming call ringtone',
          defaultValue: deviceSettings.volumeExternalSpeaker,
          type: 'slider',
          name: 'volumeExternalSpeaker'
        },
        {
          settingItemTitle: 'Button Feedback Tone',
          settingItemDescription: 'Permits a tone to be played on the speaker when a button is pressed',
          defaultValue: deviceSettings.volumeOperation,
          type: 'switch',
          name: 'volumeOperation'
        }
      ]
    }
  ];

  const substationItems = [
    {
      sectionTitle: 'Speaker',
      settings: [
        {
          settingItemTitle: 'Microphone Volume Speaker',
          settingItemDescription: 'Volume of the outgoing microphone speaker',
          defaultValue: deviceSettings.volumeOutgoingSpeaker,
          type: 'slider',
          name: 'volumeOutgoingSpeaker'
        },
        {
          settingItemTitle: 'Volume Incoming Speaker',
          settingItemDescription: 'Volume of the incoming speaker',
          defaultValue: deviceSettings.volumeIncomingSpeaker,
          type: 'slider',
          name: 'volumeIncomingSpeaker'
        },
        {
          settingItemTitle: 'Volume External Speaker',
          settingItemDescription: 'Volume of the external speaker',
          defaultValue: deviceSettings.volumeExternalSpeaker,
          type: 'slider',
          name: 'volumeExternalSpeaker'
        }
      ]
    },
    {
      sectionTitle: 'Output Sound',
      settings: [
        {
          settingItemTitle: 'Incoming Call Ringtone Volume',
          settingItemDescription: 'Volume of the incoming call ringtone',
          defaultValue: deviceSettings.volumeRingtone,
          type: 'slider',
          name: 'volumeRingtone'
        }
      ]
    },
    {
      sectionTitle: 'Handset',
      settings: [
        {
          settingItemTitle: 'Volume Outgoing Handset',
          settingItemDescription: 'Volume of the outgoing handset',
          defaultValue: deviceSettings.volumeOutgoingHandset,
          type: 'slider',
          name: 'volumeOutgoingHandset'
        },
        {
          settingItemTitle: 'Volume Incoming Handset',
          settingItemDescription: 'Volume of the incoming handset',
          defaultValue: deviceSettings.volumeIncomingHandset,
          type: 'slider',
          name: 'volumeIncomingHandset'
        }
      ]
    }
  ];

  const pagingDoorStationSettingItems = [
    {
      sectionTile: 'Speaker',
      settings: [
        {
          settingItemTitle: 'Volume Monitoring Microphone',
          settingItemDescription: 'Volume of the monitoring microphone',
          defaultValue: deviceSettings.volumeMonitoringMicrophone,
          type: 'slider',
          name: 'volumeMonitoringMicrophone'
        },
        {
          settingItemTitle: 'Speaker and Ringbacktone Volume',
          settingItemDescription: 'Volume of the speaker',
          defaultValue: deviceSettings.volumeSpeaker,
          type: 'slider',
          name: 'volumeSpeaker'
        },
        {
          settingItemTitle: 'Volume Incoming Page',
          settingItemDescription: 'Volume of the incoming page',
          defaultValue: deviceSettings.volumeIncomingPage,
          type: 'slider',
          name: 'volumeIncomingPage'
        }
      ]
    }
  ];

  const doorStationSettingItems = [
    {
      sectionTitle: 'Speaker',
      settings: [
        {
          settingItemTitle: 'Volume Monitoring Microphone',
          settingItemDescription: 'Volume of the monitoring microphone',
          defaultValue: deviceSettings.volumeMonitoringMicrophone,
          type: 'slider',
          name: 'volumeMonitoringMicrophone'
        },
        {
          settingItemTitle: 'Speaker and Ringbacktone Volume',
          settingItemDescription: 'Volume of the speaker',
          defaultValue: deviceSettings.volumeSpeaker,
          type: 'slider',
          name: 'volumeSpeaker'
        },
        {
          settingItemTitle: 'Volume Incoming Page',
          settingItemDescription: 'Volume of the incoming page',
          defaultValue: deviceSettings.volumeIncomingPage,
          type: 'slider',
          name: 'volumeIncomingPage'
        }
      ]
    }
  ];

  const SOUND_SETTINGS_BY_DEVICE_TYPE: any = {
    /* IX-DV |device type 8 |  door station */
    8: doorStationSettingItems,
    14: tenantStationSettingItems,
    15: pagingDoorStationSettingItems,
    16: answeringSettingItems,
    20: doorStationSettingItems,
    11: doorStationSettingItems,
    5: substationItems,
    10: doorStationSettingItems,
    9: pagingDoorStationSettingItems,
    4: answeringSettingItems
  };

  return (
    <>
      <Container sx={{ margin: '0' }}>
        {SOUND_SETTINGS_BY_DEVICE_TYPE[device.basicInfo.deviceType].map((section: any, index: number) => (
          <Box key={index}>
            <SoundsSection title={section.sectionTitle} settings={section.settings} device={device} />
          </Box>
        ))}
      </Container>
    </>
  );
};
