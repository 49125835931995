import React, { useState } from 'react';
import { Calendar, momentLocalizer, Views, View, SlotInfo, DayPropGetter, ToolbarProps } from 'react-big-calendar';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  InputLabel,
  Select,
  MenuItem,
  Box
} from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import 'react-toastify/dist/ReactToastify.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';

// Import our styled components
import {
  CalendarContainer,
  ToolbarContainer,
  ToolbarLabel,
  CalendarNavIconButton as NavIconButton,
  CalendarViewButton as ViewButton
} from '../Shared/Common.styled';

// Example type definitions
type ScheduleType = 'single' | 'recurring';
type RecurrenceType = 'none' | 'daily' | 'weekly' | 'monthly';

export interface CalendarEvent {
  id: string | number;
  title: string;
  start: Date;
  end: Date;
  description?: string;
  scheduleType: ScheduleType;
  recurrence: RecurrenceType;
}

const localizer = momentLocalizer(moment);

const AppCalendar: React.FC = () => {
  const { t } = useTranslation('nextIX');

  // State for all events
  const [events, setEvents] = useState<CalendarEvent[]>([]);

  // Dialog control
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  // Currently selected event for editing, if any
  const [selectedEvent, setSelectedEvent] = useState<CalendarEvent | null>(null);

  // A new or edited event data
  const [newEvent, setNewEvent] = useState<CalendarEvent>({
    id: '',
    title: '',
    start: new Date(),
    end: new Date(),
    description: '',
    scheduleType: 'single',
    recurrence: 'none'
  });

  // We store the current "view" only if needed for future logic
  const WEEK_VIEW = Views.WEEK;
  const [, setView] = useState<View>(WEEK_VIEW);

  // 1) Typed dayPropGetter
  const pastBackgroundColor = '#e0e0e0';

  const customDayPropGetter: DayPropGetter = (date) => {
    if (moment(date).isBefore(moment(), 'day')) {
      return {
        style: {
          backgroundColor: pastBackgroundColor,
          pointerEvents: 'none'
        }
      };
    }
    return { style: {} };
  };

  // 2) Generate recurring events
  const generateRecurringEvents = (event: CalendarEvent): CalendarEvent[] => {
    const { recurrence, start, end } = event;
    let currentStart = moment(start);
    let currentEnd = moment(end);

    const newEvents: CalendarEvent[] = [];

    if (recurrence === 'daily') {
      for (let i = 0; i < 30; i++) {
        newEvents.push({
          ...event,
          id: `${event.id}-${i}`,
          start: currentStart.toDate(),
          end: currentEnd.toDate()
        });
        currentStart = currentStart.add(1, 'day');
        currentEnd = currentEnd.add(1, 'day');
      }
    } else if (recurrence === 'weekly') {
      for (let i = 0; i < 10; i++) {
        newEvents.push({
          ...event,
          id: `${event.id}-${i}`,
          start: currentStart.toDate(),
          end: currentEnd.toDate()
        });
        currentStart = currentStart.add(1, 'week');
        currentEnd = currentEnd.add(1, 'week');
      }
    } else if (recurrence === 'monthly') {
      for (let i = 0; i < 12; i++) {
        newEvents.push({
          ...event,
          id: `${event.id}-${i}`,
          start: currentStart.toDate(),
          end: currentEnd.toDate()
        });
        currentStart = currentStart.add(1, 'month');
        currentEnd = currentEnd.add(1, 'month');
      }
    }

    return newEvents;
  };

  // 3) Handle slot selection (for creating a new event)
  const handleSelectSlot = ({ start, end }: SlotInfo) => {
    if (moment(start).isBefore(moment(), 'day')) return;

    setSelectedEvent(null);
    setNewEvent({
      id: '',
      title: '',
      start: start as Date,
      end: end as Date,
      description: '',
      scheduleType: 'single',
      recurrence: 'none'
    });
    setIsDialogOpen(true);
  };

  const handleAddEvent = () => {
    if (!newEvent.title.trim() || !newEvent.start || !newEvent.end) {
      toast.error(t('calendar.errors.RequiredFieldsError'));
      return;
    }
    if (newEvent.end <= newEvent.start) {
      toast.error(t('calendar.errors.EndTimeError'));
      return;
    }

    let newEvents: CalendarEvent[] = [
      {
        ...newEvent,
        id: events.length + 1
      }
    ];

    if (newEvent.scheduleType === 'recurring' && newEvent.recurrence !== 'none') {
      newEvents = generateRecurringEvents({
        ...newEvent,
        id: events.length + 1
      });
    }

    setEvents((prev) => [...prev, ...newEvents]);
    setIsDialogOpen(false);
  };

  // 4) When user clicks on an existing event
  const handleSelectEvent = (event: CalendarEvent) => {
    setSelectedEvent(event);
    setNewEvent({ ...event });
    setIsDialogOpen(true);
  };

  // 5) Update an existing event
  const handleUpdateEvent = () => {
    if (!newEvent.title.trim() || !newEvent.start || !newEvent.end) {
      toast.error(t('calendar.errors.RequiredFieldsError'));
      return;
    }
    if (newEvent.end <= newEvent.start) {
      toast.error(t('calendar.errors.EndTimeError'));
      return;
    }

    if (!selectedEvent) {
      return;
    }

    // If it was part of a recurring series, remove all from that series
    const baseId = String(selectedEvent.id).split('-')[0];
    const filteredEvents = events.filter((ev) => String(ev.id).split('-')[0] !== baseId);

    let updated: CalendarEvent[] = [
      {
        ...newEvent,
        id: baseId
      }
    ];

    if (newEvent.scheduleType === 'recurring' && newEvent.recurrence !== 'none') {
      updated = generateRecurringEvents({
        ...newEvent,
        id: baseId
      });
    }

    setEvents([...filteredEvents, ...updated]);
    setSelectedEvent(null);
    setIsDialogOpen(false);
  };

  // 6) Delete an event (also deletes entire recurring series)
  const handleDeleteEvent = () => {
    if (selectedEvent) {
      const baseId = String(selectedEvent.id).split('-')[0];
      setEvents((prev) => prev.filter((e) => String(e.id).split('-')[0] !== baseId));
    }
    setIsDialogOpen(false);
  };

  // 7) Custom Toolbar with RBC's ToolbarProps
  const CustomToolbar: React.FC<ToolbarProps<CalendarEvent, object>> = (props) => {
    const { label, onNavigate, onView } = props;

    const goToBack = () => onNavigate('PREV');
    const goToNext = () => onNavigate('NEXT');
    const changeView = (newView: View) => {
      onView(newView);
      setView(newView);
    };

    return (
      <ToolbarContainer>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <NavIconButton onClick={goToBack} edge="start">
            <ArrowBackIosNewIcon />
          </NavIconButton>

          <ToolbarLabel variant="h5">{label}</ToolbarLabel>

          <NavIconButton onClick={goToNext}>
            <ArrowForwardIosIcon />
          </NavIconButton>
        </Box>

        <Box sx={{ display: 'flex', marginLeft: 'auto' }}>
          <ViewButton onClick={() => changeView(Views.MONTH)} variant="outlined" color="primary">
            {t('calendar.views.monthly')}
          </ViewButton>
          <ViewButton onClick={() => changeView(Views.WEEK)} variant="outlined" color="primary">
            {t('calendar.views.weekly')}
          </ViewButton>
          <ViewButton onClick={() => changeView(Views.DAY)} variant="outlined" color="primary">
            {t('calendar.views.today')}
          </ViewButton>
        </Box>
      </ToolbarContainer>
    );
  };

  return (
    <CalendarContainer>
      <Calendar<CalendarEvent>
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        selectable
        onSelectSlot={handleSelectSlot}
        onSelectEvent={handleSelectEvent}
        showMultiDayTimes
        dayPropGetter={customDayPropGetter} // typed dayPropGetter
        style={{ height: '100%', borderRadius: '12px' }}
        components={{ toolbar: CustomToolbar }}
        defaultView={Views.WEEK}
        views={[Views.MONTH, Views.WEEK, Views.DAY]}
        onView={(newView) => setView(newView)}
      />

      <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
        <DialogTitle sx={{ textAlign: 'center' }}>
          {selectedEvent ? t('calendar.updateEvent') : t('calendar.addEvent')}
        </DialogTitle>
        <DialogContent>
          <FormControl component="fieldset" sx={{ mb: 3 }}>
            <RadioGroup
              row
              value={newEvent.scheduleType}
              onChange={(e) => setNewEvent({ ...newEvent, scheduleType: e.target.value as ScheduleType })}
            >
              <FormControlLabel value="single" control={<Radio />} label={t('calendar.scheduleType.single')} />
              <FormControlLabel value="recurring" control={<Radio />} label={t('calendar.scheduleType.recurring')} />
            </RadioGroup>
          </FormControl>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            <TextField
              autoFocus
              margin="dense"
              label={t('calendar.title')}
              fullWidth
              value={newEvent.title}
              onChange={(e) => setNewEvent({ ...newEvent, title: e.target.value })}
            />
            <TextField
              margin="dense"
              label={t('calendar.startTime')}
              type="datetime-local"
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={newEvent.start ? moment(newEvent.start).format('YYYY-MM-DDTHH:mm') : ''}
              onChange={(e) => setNewEvent({ ...newEvent, start: new Date(e.target.value) })}
            />
            <TextField
              margin="dense"
              label={t('calendar.endTime')}
              type="datetime-local"
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={newEvent.end ? moment(newEvent.end).format('YYYY-MM-DDTHH:mm') : ''}
              onChange={(e) => setNewEvent({ ...newEvent, end: new Date(e.target.value) })}
            />
            <TextField
              margin="dense"
              label={t('calendar.description')}
              fullWidth
              multiline
              rows={4}
              value={newEvent.description}
              onChange={(e) => setNewEvent({ ...newEvent, description: e.target.value })}
            />

            {newEvent.scheduleType === 'recurring' && (
              <FormControl fullWidth>
                <InputLabel id="Recurrence-label">{t('calendar.recurrenceLabel')}</InputLabel>
                <Select
                  value={newEvent.recurrence}
                  labelId="Recurrence-label"
                  onChange={(e) => setNewEvent({ ...newEvent, recurrence: e.target.value as RecurrenceType })}
                  label="Recurrence"
                >
                  <MenuItem value="none">{t('calendar.recurrence.none')}</MenuItem>
                  <MenuItem value="daily">{t('calendar.recurrence.daily')}</MenuItem>
                  <MenuItem value="weekly">{t('calendar.recurrence.weekly')}</MenuItem>
                  <MenuItem value="monthly">{t('calendar.recurrence.monthly')}</MenuItem>
                </Select>
              </FormControl>
            )}
          </Box>
        </DialogContent>

        <DialogActions>
          {selectedEvent && (
            <Button onClick={handleDeleteEvent} color="error">
              {t('calendar.deleteEvent')}
            </Button>
          )}
          <Button onClick={() => setIsDialogOpen(false)} color="secondary">
            {t('calendar.cancel')}
          </Button>
          <Button onClick={selectedEvent ? handleUpdateEvent : handleAddEvent} color="primary">
            {selectedEvent ? t('calendar.update') : t('calendar.addEvent')}
          </Button>
        </DialogActions>
      </Dialog>
    </CalendarContainer>
  );
};

export default AppCalendar;
