/**
 * Rounds a given number to a specified precision.
 *
 * @param {number} num - The number to be rounded.
 * @param {number} precision - The number of decimal places to round to.
 * @returns {number} The rounded number.
 */
export const roundTo = (num: number, precision: number): number => {
  const factor = Math.pow(10, precision);
  return Math.round(num * factor) / factor;
};

/**
 * Converts a number to a string representation with a specified number of decimal places.
 *
 * @param {number} num - The number to be converted.
 * @param {number} precision - The number of decimal places to format the number to.
 * @return {string} A string representation of the number with the specified precision.
 */
export function toPrecisionString(num: number, precision: number): string {
  const factor = Math.pow(10, precision);
  return (Math.floor(num * factor) / factor).toFixed(precision);
}

/**
 * Counts the total number of digit characters in a given string.
 *
 * This function takes a string as input and calculates the number of numeric
 * digit characters (0-9) present in the string. If no digits are found, the
 * function returns 0.
 *
 * @param {string} str - The input string to evaluate.
 * @returns {number} The count of numeric digit characters in the input string.
 */
export const countDigitsInString = (str: string): number => {
  return (str.match(/\d/g) || []).length;
};
