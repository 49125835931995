import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { INTERNATIONAL_PHONE_REGEX, ZIP_CODE_REGEX } from 'shared/constants/regex';

const useValidationSchemas = () => {
  const { t } = useTranslation(); // Initialize the translation hook

  // Login Validation Schema
  const loginValidation = yup.object().shape({
    email: yup.string().email(t('Validations.Enter_Valid_Email')).required(t('Validations.Email_Required')),
    password: yup.string().min(8, t('Validations.Password_Min_Length')).required(t('Validations.Password_Required'))
  });

  const mfaValidation = yup.object().shape({
    verificationCode: yup.string().required(t('Validations.Required'))
  });

  // Registration Validation Schema
  const registerValidation = yup.object().shape({
    firstName: yup
      .string()
      .matches(/^[a-zA-Z\s]*$/, t('Special_Characters_are_not_allowed'))
      .required(t('Validations.Required')),
    lastName: yup
      .string()
      .matches(/^[a-zA-Z\s]*$/, t('Special_Characters_are_not_allowed'))
      .required(t('Validations.Required')),
    email: yup
      .string()
      .email(t('Validations.Email_Not_Valid'))
      .matches(
        // Improving the email validation regex. Need to test
        /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        t('Validations.Email_Valid')
      )
      .required(t('Validations.Required')),
    phoneNumber: yup
      .string()
      .matches(INTERNATIONAL_PHONE_REGEX, t('Valid_International_Phone_Format'))
      // Commented out below because the AI Assistant says it will not work for the `^\+(\d{3})(\d{3})(\d{4})$` sequence
      .test('numDigits', t('Valid_Phone_Character_Count'), (value) => {
        const digitCount = (value?.match(/\d/g) || []).length;
        return digitCount >= 10 && digitCount <= 12;
      })
      .max(18, t('Validations.Phone_Max_Length')) // Allows us to handle long numbers such as '+1 (888) 888-8888'; frontend counts the number of digits, which caps out at 12
      .required(t('Validations.Required'))
      .transform((value) => value.replace(/^\+(\d{3})(\d{3})(\d{4})$/, '+$1 ($2) $3-$4')),
    companyName: yup.string().matches(/^[a-zA-Z\s]*$/, t('Special_Characters_are_not_allowed')),
    countryId: yup.number().required(t('Validations.Required')),
    address: yup
      .string()
      .matches(/^[a-zA-Z\s.]*$/, t('Special_Characters_are_not_allowed'))
      .required(t('Validations.Required')),
    address2: yup.string().matches(/^[a-zA-Z\s.]*$/, t('Special_Characters_are_not_allowed')),
    city: yup
      .string()
      .matches(/^[a-zA-Z\s]*$/, t('Special_Characters_are_not_allowed'))
      .required(t('Validations.Required')),
    stateId: yup.number().required(t('Validations.Required')),
    // This watches the value of 'countryId' and updates the validation for each different country
    zipCode: yup.string().when('countryId', {
      is: 2,
      then: (schema) =>
        schema
          .min(5, t('Dynamic_min', { min: 5 }))
          .max(5, t('Dynamic_max', { max: 5 }))
          .matches(ZIP_CODE_REGEX.unitedStates, t('Invalid_US_Zip_Code'))
          .required(t('Validations.Required')),
      otherwise: (schema) =>
        schema
          .min(6, t('Dynamic_min', { min: 6 }))
          .max(7, t('Dynamic_max', { max: 7 }))
          .matches(ZIP_CODE_REGEX.canada, t('Invalid_Canadian_Zip_Code'))
          .required(t('Validations.Required'))
    }),
    password: yup.string().required(t('Validations.Required')),
    passwordConfirm: yup
      .string()
      .required(t('Validations.Required'))
      .oneOf([yup.ref('password')], t('Validations.Password_Match'))
  });

  // Confirm Email Validation Schema
  const confirmEmailValidation = yup.object().shape({
    verificationCode: yup.string().required(t('Validations.Required'))
  });

  // Create Password Validation Schema
  const createPWDValidation = yup.object().shape({
    password: yup.string().required(t('Validations.Required')),
    passwordConfirm: yup
      .string()
      .required(t('Validations.Required'))
      .oneOf([yup.ref('password')], t('Validations.Password_Match'))
  });

  // Reset Password Validation Schema
  const resetPWDValidation = yup.object().shape({
    email: yup.string().email(t('Validations.Enter_Valid_Email')).required(t('Validations.Email_Required'))
  });

  return {
    loginValidation,
    registerValidation,
    confirmEmailValidation,
    createPWDValidation,
    resetPWDValidation,
    mfaValidation
  };
};

export default useValidationSchemas;
